/**
 *@Author:navies
 *@Date:2021-08-31
 *@Project:后台管理系统
 */

// eslint-disable-next-line no-unused-vars
import {get, post} from '@/plugins/http.js'

export const baseUrl = process.env.VUE_APP_API_URL;

/**
 * 市代理列表
 * @param data
 */
export const list = (data) => {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
        post(baseUrl + "system/agent/list/cityAgent", data).then((res) => {
            resolve(res);
        });
    });

};

/**
 * 市代理列表-禁用
 * @param data
 */
export const close = (data) => {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
        post(baseUrl + "system/agent/status/cityAgent/" + data).then((res) => {
            resolve(res);
        });
    });
};

/**
 * 市代理列表-申请
 * @param data
 */
export const apply = (data) => {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
        post(baseUrl + "system/agent/apply/cityAgent", data).then((res) => {
            resolve(res);
        });
    });


};
